import { sprintf } from "@wordpress/i18n";
import BSHelper from "../BSHelper";

export default function NewsSection({
	newsList,
	sectionTitle,
	sectionMarginTop,
	sectionMarginBottom,
	phrases,
}) {
	const arrowClassIcon =
		" group-hover:translate-x-1 rtl:group-hover:-translate-x-1 transition-all " +
		(BSHelper.isPageRTL() ? "fa-arrow-left-long" : "fa-arrow-right-long");

	return (
		<section
			className={`${sectionMarginTop ?? ""} ${sectionMarginBottom ?? ""}`}
		>
			<div className="items-center justify-between block mb-5 sm:flex sm:mb-10">
				<p className="text-2xl font-bold text-body lg:text-3xl">
					{sectionTitle ?? phrases.headlines}
				</p>
				<a
					href="/news"
					className="mt-5 text-sm font-medium no-underline transition-all border-2 border-transparent border-solid hover:underline hover:text-primary text-[#176ae5] sm:text-lg sm:ms-20 text-end sm:mt-0"
				>
					{phrases.view_all}
				</a>
			</div>
			{newsList.length > 0 && (
				<div className="grid grid-cols-1 lg:grid-cols-2">
					<div className="flex flex-col mb-10 lg:mb-5 big-item">
						<a
							href={newsList[0].link ?? "#"}
							className="md:h-80 h-40 w-full lg:w-[560px] rounded-2xl bg-lightGray transition-all group overflow-hidden"
						>
							<img
								src={`${
									newsList[0].thumbnail ?? "https://via.placeholder.com/560x309"
								}`}
								alt=""
								className="object-cover w-full h-full transition-all group-hover:scale-110"
								loading="lazy"
							/>
						</a>
						<div className="flex flex-row">
							<div className="flex items-center mt-5 me-6">
								<svg
									width="18"
									height="18"
									viewBox="0 0 18 18"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<rect
										x="2.25"
										y="4.5"
										width="13.5"
										height="11.25"
										rx="1.25"
										stroke="#909DA2"
									/>
									<path d="M3 8.25H15" stroke="#909DA2" strokeLinecap="round" />
									<path
										d="M6.75 12H11.25"
										stroke="#909DA2"
										strokeLinecap="round"
									/>
									<path
										d="M6 2.25L6 5.25"
										stroke="#909DA2"
										strokeLinecap="round"
									/>
									<path
										d="M12 2.25L12 5.25"
										stroke="#909DA2"
										strokeLinecap="round"
									/>
								</svg>
								<p className="text-xs font-medium text-darkGray sm:leading-6 md:text-base ms-2 text-nowrap">
									{BSHelper.isPageRTL()
										? BSHelper.formatDate(
												new Date(newsList[0].created_date),
												"ar-EG",
										  )
										: BSHelper.formatDate(new Date(newsList[0].created_date))}
								</p>
							</div>
							<div className="flex items-center mt-5">
								<svg
									width="18"
									height="19"
									viewBox="0 0 18 19"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<circle cx="9" cy="9.78125" r="6.5" stroke="#909DA2" />
									<path
										d="M12.375 9.78125H9.15625C9.06996 9.78125 9 9.71129 9 9.625V7.15625"
										stroke="#909DA2"
										strokeLinecap="round"
									/>
								</svg>
								<p className="text-xs font-medium leading-6 text-darkGray sm:text-base ms-2 text-nowrap">
									{sprintf(
										/* translators: %s: number of minutes */
										phrases.minutes_of_reading,
										BSHelper.getReadingTime(newsList[0].content),
									)}
								</p>
							</div>
						</div>
						<a
							className="inline-block mt-3 overflow-hidden text-lg font-bold transition-all text-body max-h-14 hover:text-secondary"
							href={newsList[0].link ?? "#"}
						>
							{newsList[0].title}
						</a>
						<a
							className="inline-block max-w-lg mt-3 overflow-hidden text-lg font-normal transition-all text-body max-h-24 hover:text-secondary"
							href={newsList[0].link ?? "#"}
						>
							{newsList[0].meta_data.short_description}
						</a>
					</div>

					<div className="flex flex-col items-end lg:ms-10">
						{
							// slice(1) will remove the first element of the array (newsList[0])
							// which is the main news item that we already rendered above
							// map() will loop through the rest of the array and return a new array
							// of JSX elements, each one representing a news item
							// the index parameter is the index of each item in the array
							// which we don't use in this case but it's available if we need it
							newsList.slice(1).map((item, index) => (
								<div
									key={index}
									className="flex flex-row items-start max-w-lg mb-6 lg:mb-4 small-item md:flex-row"
								>
									<a
										href={item.link}
										className="h-[119px] w-[160px] md:me-10 rounded-2xl bg-lightGray transition-all group overflow-hidden"
									>
										<img
											src={`${
												item.thumbnail ?? "https://via.placeholder.com/160x119"
											}`}
											alt=""
											className="object-cover w-full h-full transition-all group-hover:scale-110"
											loading="lazy"
										/>
									</a>
									<div className="flex flex-col flex-1">
										<div className="flex flex-row">
											<div className="flex items-start mt-3 me-6">
												<svg
													width="18"
													height="18"
													viewBox="0 0 18 18"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<rect
														x="2.25"
														y="4.5"
														width="13.5"
														height="11.25"
														rx="1.25"
														stroke="#909DA2"
													/>
													<path
														d="M3 8.25H15"
														stroke="#909DA2"
														strokeLinecap="round"
													/>
													<path
														d="M6.75 12H11.25"
														stroke="#909DA2"
														strokeLinecap="round"
													/>
													<path
														d="M6 2.25L6 5.25"
														stroke="#909DA2"
														strokeLinecap="round"
													/>
													<path
														d="M12 2.25L12 5.25"
														stroke="#909DA2"
														strokeLinecap="round"
													/>
												</svg>
												<p className="text-xs font-medium text-darkGray sm:leading-6 md:text-base ms-2 text-nowrap">
													{BSHelper.isPageRTL()
														? BSHelper.formatDate(
																new Date(item.created_date),
																"ar-EG",
														  )
														: BSHelper.formatDate(new Date(item.created_date))}
												</p>
											</div>
											<div className="flex items-center mt-3">
												<svg
													width="18"
													height="19"
													viewBox="0 0 18 19"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<circle
														cx="9"
														cy="9.78125"
														r="6.5"
														stroke="#909DA2"
													/>
													<path
														d="M12.375 9.78125H9.15625C9.06996 9.78125 9 9.71129 9 9.625V7.15625"
														stroke="#909DA2"
														strokeLinecap="round"
													/>
												</svg>
												<p className="text-xs font-medium leading-6 text-darkGray sm:text-base ms-2 text-nowrap">
													{sprintf(
														/* translators: %s: number of minutes */
														phrases.minutes_of_reading,
														BSHelper.getReadingTime(item.content),
													)}
												</p>
											</div>
										</div>
										<a
											className="mt-3 overflow-hidden text-sm font-bold transition-all text-body sm:text-lg max-h-14 hover:text-secondary"
											href={item.link}
										>
											{item.title}
										</a>
									</div>
								</div>
							))
						}
					</div>
				</div>
			)}
		</section>
	);
}
