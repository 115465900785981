/**
 * React hook that is used to mark the block wrapper element.
 * It provides all the necessary props like the class name.
 *
 * @see https://developer.wordpress.org/block-editor/reference-guides/packages/packages-block-editor/#useblockprops
 */
import { useState, useEffect, useCallback } from "@wordpress/element";
import { createRoot } from "react-dom/client";

/**
 * Lets webpack process CSS, SASS or SCSS files referenced in JavaScript files.
 * Those files can contain any CSS code that gets applied to the editor.
 *
 * @see https://www.npmjs.com/package/@wordpress/scripts#using-css
 */
import "./editor.scss";
import { Spinner } from "@wordpress/components";
import NewsSection from "./NewsSection.component";
import { fetchData } from "../api";

/**
 * The edit function describes the structure of your block in the context of the
 * editor. This represents what the editor will render when the block is used.
 *
 * @see https://developer.wordpress.org/block-editor/reference-guides/block-api/block-edit-save/#edit
 *
 * @return {Element} Element to render.
 */
export default function View() {
	const [newsList, setNewsList] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [totalPages, setTotalPages] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 4;
	const rootElement = document.getElementById("rootNewsList");
	const sectionTitle = rootElement.getAttribute("data-section-title");

	const fetchNewsFeeds = useCallback(
		async (page = 1) => {
			setLoading(true);
			setError(null);

			try {
				// console.log(`Fetching newsFeeds for page ${page}`);
				const response = await fetchData(
					`/bseitwp/v1/newsfeeds?options[paginate]=1&options[limit]=${itemsPerPage}&options[page]=${page}`,
				);
				// console.log("API Response:", response);
				setNewsList(response.data.data);
				setTotalPages(response.data.pagination.total_pages);
			} catch (error) {
				setError(error);
			} finally {
				setLoading(false);
			}
		},
		[itemsPerPage],
	);

	useEffect(() => {
		fetchNewsFeeds(currentPage);
	}, [fetchNewsFeeds, currentPage]);

	return (
		<>
			{loading && (
				<div className="flex items-center justify-center w-full py-16">
					<Spinner
						style={{
							height: "calc(2px * 20)",
							width: "calc(2px * 20)",
						}}
					/>
				</div>
			)}

			{error && <p>{error.message}</p>}

			{!loading && newsList.length > 0 && (
				<NewsSection newsList={newsList} sectionTitle={sectionTitle}  phrases={bseitEditorVars.phrases} />
			)}
		</>
	);
}

const root = createRoot(document.getElementById("rootNewsList"));
root.render(<View />);
