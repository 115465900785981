import Constants from "./constants";

class BSHelper {
	/**
	 * Checks if the current page direction is right-to-left ('rtl').
	 *
	 * @return {boolean} `true` if the direction is 'rtl', `false` otherwise.
	 */
	isPageRTL() {
		return document.dir === "rtl";
	}

	/**
	 * Checks if the current page is in English.
	 *
	 * @return {boolean} `true` if the language is English, `false` otherwise.
	 */
	isEnglishLanguage() {
		return document.documentElement.lang === "en";
	}

	/**
	 * Checks if the current page is in Arabic.
	 *
	 * @return {boolean} `true` if the language is Arabic, `false` otherwise.
	 */
	isArabicLanguage() {
		return document.documentElement.lang === "ar";
	}

	/**
	 * Formats a given date into a string representation based on the specified locale.
	 *
	 * @param {Date} date - The date to be formatted.
	 * @param {string} [locale='en-US'] - The locale to use for formatting. Defaults to 'en-US'.
	 * @return {string} The formatted date string.
	 */
	formatDate(date, locale = "en-US", isWeekDayLong = false) {
		const dateOptions = {
			year: "numeric",
			month: "short",
			day: "numeric",
		};
		if (isWeekDayLong) {
			dateOptions.weekday = "long";
		}

		return new Intl.DateTimeFormat(locale, dateOptions).format(date);
	}

	getReadingTime(text) {
		if (text !== undefined || text !== null || text !== "") {
			const wpm = 225; // Average words per minute
			const words = text.trim().split(/\s+/).length;
			const readingTime = Math.ceil(words / wpm);
			return readingTime;
		}
	}

	/**
	 * Trims a multibyte string to a specified width.
	 *
	 * @param {string} str - The input string to be trimmed.
	 * @param {string} start - The starting character of the trim.
	 * @param {number} length - The desired width of the trimmed string.
	 * @param {string} truncate_string - The string to append when truncating.
	 * @return {string} The trimmed string.
	 */
	mb_strimwidth(str, start, length, truncateString) {
		const charCount = this.getCharCount(str);
		const startCharIndex = this.getCharIndex(str, start);
		const endCharIndex = startCharIndex + length;

		if (endCharIndex > charCount) {
			return truncateString + "...";
		}

		return str.substring(startCharIndex, endCharIndex);
	}

	/**
	 * Returns the character count of a given string, considering multibyte characters.
	 *
	 * @param {string} str - The input string to count characters from.
	 * @return {number} The total character count of the input string.
	 */
	getCharCount(str) {
		let count = 0;
		for (let i = 0; i < str.length; i++) {
			const charCode = str.charCodeAt(i);
			if (charCode > 255) {
				count += 2;
			} else {
				count++;
			}
		}
		return count;
	}

	/**
	 * Returns the index of the first occurrence of a specified character in a string.
	 *
	 * @param {string} str - The input string to search for the character.
	 * @param {string} char - The character to search for in the string.
	 * @return {number} The index of the first occurrence of the character, or the last index if not found.
	 */
	getCharIndex(str, char) {
		let index = 0;
		for (let i = 0; i < str.length; i++) {
			if (str[i] === char) {
				index = i;
				break;
			}
		}
		return index;
	}
	/**
	 * Truncates a string to a specified length and adds an ellipsis if needed.
	 *
	 * @param {string} str - The input string to truncate
	 * @param {number} n - The maximum length of the truncated string
	 * @returns {string} The truncated string with ellipsis if length exceeded, otherwise original string
	 */
	truncate(str, n) {
		return str.length > n ? str.slice(0, n - 1) + "&hellip;" : str;
	}

	getApiRootUrl() {
		const envType = window.bseitEditorVars?.envType || "prod";

		switch (envType) {
			case "dev":
				return Constants.APIS.ROOT_URL.DEV;
			case "qc":
				return Constants.APIS.ROOT_URL.QC;
			case "stg":
				return Constants.APIS.ROOT_URL.STG;
			case "prod":
			default:
				return Constants.APIS.ROOT_URL.PROD;
		}
	}

	/**
	 * Generic fetch function for paginated and filtered data
	 *
	 * @param {string} apiName - API identifier (e.g., 'JOBS', 'VOLUNTEER')
	 * @param {string} endpoint - API endpoint (e.g., 'list', 'types')
	 * @param {Object} params - Query parameters
	 * @return {Promise} API response
	 */
	async fetchExternalApiData(apiUrl, params) {
		const {
			searchQuery = "",
			sortBy = "",
			pageIndex = 1,
			pageSize = 10,
			type = "",
			workEnvironment = "",
			experienceLevel = "",
			minSalary = "",
			maxSalary = "",
		} = params;

		const queryString = `?keyWord=${searchQuery}&sortBy=${sortBy}&pageIndex=${pageIndex}&pageSize=${pageSize}&type=${type}&workEnvironment=${workEnvironment}&experienceLevel=${experienceLevel}&minSalary=${minSalary}&maxSalary=${maxSalary}`;

		const response = await fetch(`${apiUrl}/${queryString}`, {
			method: "GET",
			headers: {
				"Accept-Language": document.documentElement.lang,
			},
		});

		if (!response.ok) {
			throw new Error(`Error: ${response.status}`);
		}

		return await response.json();
	}
}

export default new BSHelper();
